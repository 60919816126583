import React, {useState} from "react";
import { Grid, Typography, Button, Box } from '@material-ui/core'
import monitor from './Monitor.png';
import { Link } from "../util/router.js";
import { useAuth } from "../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
 
  image: {
    // width: "100%",
    // maxWidth: "800px",
    objectFit: "cover",
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
  textOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "'Bebas Neue', sans-serif",
    fontSize: "80px",
    color: "orange",
    textShadow: "4px 4px 10px rgba(0, 0, 0, 0.7), 0px 0px 20px orange",
    letterSpacing: "5px",
    textAlign: "center",
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.3%", // Aspect ratio (16:9)
    height: 0,
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "15px",
      boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
    },
  },
  topContainer:{
    maxWidth: "1400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(4), 
  },
}));

const VideoSection = ({url}) => {
  const classes = useStyles();

  return (
    <Box className={classes.videoContainer} >
      <iframe
        src={url}
        title="Sports Betting Dashboard Demo"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

    </Box>
  );
};
const LandingPanel1v4 = ({
  campaign,
}) => {

  const classes = useStyles();
  const auth = useAuth();
  
  
  return (
    <Box
        className={classes.topContainer}
    >
      <Grid container spacing={4}>
        <Grid
          container
          width={'100%'}
          item
          xs={12}
          spacing={4}
          alignItems="center"
          justifyContent="center"
          direction="row"
          wrap="wrap"
        >
          <Grid item xs={12} sm={5}>
            <Typography variant="h1" align="right">{campaign.title}</Typography>
            <Typography variant="h4" align="right">Stats</Typography>
            <Typography variant="h4" align="right">Sportsbooks</Typography>
            <Typography variant="h4" align="right">Odds Histories</Typography>
            <Typography variant="h4" align="right">Injuries & Transactions</Typography>
          </Grid>

          <Grid item xs={12} sm={7}>
            <VideoSection url={campaign.video}/>
          </Grid>
        </Grid>
        <Grid 
          container 
          className={classes.buttonWrapper}
          spacing={2} 
          alignItems="center" 
          justifyContent="center"
        >
            <Button 
              component={Link}
              to={auth.user ? `/purchase/$standard` : `/auth/signup?next=/purchase/standard`}
              size="large"
              variant="contained"
              className={classes.button}
            ><Typography variant="h4">

              Try StatFactory.io
              </Typography>
            </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPanel1v4;