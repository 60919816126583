import React, { useEffect, useContext, useState } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    // marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',

    /* Larger screens (XL and up) */
    [theme.breakpoints.up('xl')]: {
      width: '49%',
      marginRight: theme.spacing(1),
      minWidth: 500,
      maxHeight: 700,
    },

    /* Large screens (LG) */
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1),
      width: '48%',
      maxHeight: 700,
    },

    /* Medium screens (MD and below) */
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    /* Small screens (SM and below).
       If minWidth = 500 causes horizontal scrolling on smaller devices,
       you can remove it or set it to 'auto'. */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // Remove or reduce minWidth to avoid overflow
      // minWidth: 'auto',
    },
  },
  tabPanel: {
    padding: theme.spacing(1),
    maxHeight: 500,
    overflowY: 'auto',
  },
  transactionItem: {
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
    width: 35,
    height: 35,
  },
  dateHeader: {
    marginTop: theme.spacing(1),
  },
  divider: {},
  iconContainer: {
    width: 55,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `0px solid ${(props) => props.secondaryColor}`,
    borderRadius: theme.shape.borderRadius * 2,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  iconImage: {
    width: '90%',
    height: '90%',
  },
  tabContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  tabLabel: {
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const Transactions = () => {
  const { game, fetchFromAPI } = useContext(DashboardContext);
  const [homeTransactions, setHomeTransactions] = useState([]);
  const [awayTransactions, setAwayTransactions] = useState([]);
  const [homeTeamLogo, setHomeTeamLogo] = useState(null);
  const [awayTeamLogo, setAwayTeamLogo] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  // Ensure that `game` exists before accessing properties
  const visitorColors = game
    ? {
        primaryColor: game.visitor.primaryColor || '#000',
        secondaryColor: game.visitor.secondaryColor || '#333',
      }
    : { primaryColor: '#000', secondaryColor: '#333' };

  const homeColors = game
    ? {
        primaryColor: game.home.primaryColor || '#000',
        secondaryColor: game.home.secondaryColor || '#333',
      }
    : { primaryColor: '#000', secondaryColor: '#333' };

  // Create two sets of styles: one using visitor colors, one with home colors.
  // This ensures tab label styling (iconContainer, tabLabel, etc.) uses
  // each team's colors, if needed.
  const visitorClasses = useStyles(visitorColors);
  const homeClasses = useStyles(homeColors);

  useEffect(() => {
    if (game) {
      const home = game.home.teamName;
      const away = game.visitor.teamName;
      const league = game.home.league;
      const data = { home, away, league };

      fetchFromAPI('v3/transactions', 'POST', data).then((response) => {
        setAwayTeamLogo(
          <img src={game.visitor.logoURL} alt="" width="40" height="40" onError={(e) => {
            e.target.style.display = 'none'; // Hide the image
          }}/>
        );
        setHomeTeamLogo(
          <img src={game.home.logoURL} alt="" width="40" height="40" onError={(e) => {
            e.target.style.display = 'none'; // Hide the image
          }}/>
        );
        setAwayTransactions(processTransactions(response[1]));
        setHomeTransactions(processTransactions(response[3]));
      });
    }
  }, [game]);

  const processTransactions = (transactionsArray) => {
    const grouped = {};
    transactionsArray.forEach((transaction) => {
      const date = new Date(transaction.date).toLocaleDateString();
      if (!grouped[date]) grouped[date] = [];
      grouped[date].push(transaction.transaction);
    });
    return grouped;
  };

  const renderTransactionList = (transactions, teamLogo) => {
    return Object.entries(transactions).map(([date, items], index) => (
      <React.Fragment key={index}>
        <Typography variant="subtitle1" className={visitorClasses.dateHeader}>
          {date}
        </Typography>
        <List>
          {items.map((item, idx) => (
            <ListItem key={idx} className={visitorClasses.transactionItem}>
              {teamLogo && (
                <span className={visitorClasses.icon}>{teamLogo}</span>
              )}
              <Typography variant="body1">{item}</Typography>
            </ListItem>
          ))}
        </List>
        {index < Object.entries(transactions).length - 1 && (
          <Divider className={visitorClasses.divider} />
        )}
      </React.Fragment>
    ));
  };

  if (!game) {
    return null; // Render nothing if `game` is null or undefined
  }

  return (
    <Box component={Paper} className={visitorClasses.componentContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
        Transactions & Injuries
      </Typography>

      {/*
        The AppBar+Tabs combo is already fairly mobile-friendly, especially
        using variant="fullWidth". If you need more space, consider
        adjusting the margin/padding in the parent Box or removing
        the Paper's overflow if it causes horizontal scroll.
      */}
      <AppBar position="sticky" color="default">
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          variant="fullWidth"
        >
          <Tab
            label={
              <div className={visitorClasses.tabContent}>
                <div className={visitorClasses.iconContainer}>
                  {awayTeamLogo}
                </div>
                <Typography className={visitorClasses.tabLabel}>
                  {game.visitor.teamName}
                </Typography>
              </div>
            }
          />
          <Tab
            label={
              <div className={homeClasses.tabContent}>
                <Typography className={homeClasses.tabLabel}>
                  {game.home.teamName}
                </Typography>
               { homeTeamLogo}
              </div>
            }
          />
        </Tabs>
      </AppBar>

      <Box className={visitorClasses.tabPanel}>
        {tabIndex === 0 && renderTransactionList(awayTransactions, awayTeamLogo)}
        {tabIndex === 1 && renderTransactionList(homeTransactions, homeTeamLogo)}
      </Box>
    </Box>
  );
};

export default React.memo(Transactions);
