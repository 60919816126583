import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Breadcrumbs, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v4Context.js';
import TimelineIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
  topPanel: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '100%',
  },
  bottomPanel: {
    width: '100%',
  },
  middlePanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
  titlePane: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      padding: theme.spacing(1), 
    },

    
  },
  visitorPane: {
    width: '20%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      // width: '30%',
      padding: theme.spacing(1), 
    },
  },
  homePane: {
    width: '10%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      padding: theme.spacing(1), 
    },
    // marginRight: theme.spacing(4),
  },
  icon: {
    width: 30,
    height: 30,
    margin: theme.spacing(0),
    cursor: 'pointer',
  },
  icontoggled: {
    color: '#00FF00', // Bright green color for the icon
    filter: 'drop-shadow(0 0 5px #00FF00)', // Green glowing drop shadow
  },
  breadcrumbs: {
    flexGrow: 1,
  },
  viewsIcon: {
    width: 15,
    height: 15,
    // margin: theme.spacing(0),
  },
  availableView: {
    width: 17,
    height: 17,
    margin: theme.spacing(.2),
    backgroundColor: "rgba(0, 255, 0, 0.2)",//"rgba(255, 0, 0, 0.03)" : "rgba(0, 255, 0, 0.03)";
    borderRadius: 5,
    padding: theme.spacing(.2),
    //Drop shadow
    filter: 'drop-shadow(0 0 .5px black)',
  },
  unavailableView: {
    color: 'rgba(0, 0, 0, .3)',
    margin: theme.spacing(.2),
    padding: theme.spacing(.2),
    backgroundColor: "rgba(255, 0, 0, 0.2)",
    backgroundColor: 'rgba(128, 128, 128, 0.2)',
    width: 17,
    height: 17,
    borderRadius: 5,
    // filter: 'drop-shadow(0 0 5px black)',
  },
  derogIcon: {
    color: 'orange',
    width: 20,
    height: 20,
    margin: theme.spacing(.2),
    padding: theme.spacing(.2),
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '10%',
  },
}));


const IndexReference = (index) => {
  switch (index) {
    case 0:
      return ['Season Total'];
    case 1:
      return ['Season as Away / Home'];
    case 2:
      return ['Season as Underdog / Favorite'];
    case 3:
      return ['Season Conf. / Non-Conf.'];
    case 4:
      return ['Last 3 Games'];
    case 5:
      return ['Last 3 Games as Away / Home'];
    case 6:
      return ['Last 3 Games as Underdog / Favorite'];
    case 7:
      return ['Last 3 Games Conf. / Non-Conf.'];
    default:
      return [];
  }
};

const DataRow = ({ view, title, visitorValue, homeValue, splitIndex, reference }) => {
  const classes = useStyles();
  const { chartDataList, setChartDataList, getDictionaryEntry } = useContext(DashboardContext);
  const [showChart, setShowChart] = useState(false)
  const [visVal, setVisVal] = useState(visitorValue)
  const [homeVal, setHomeVal] = useState(homeValue)

  let dictionaryReference = getDictionaryEntry(reference.ref)

  useEffect(() => {
    // Check if sendToChart is already in chartDataList when the component mounts or when chartDataList changes
    const sendToChart = [reference.ref, reference.index];
    const isInChart = chartDataList.some(
      (item) => JSON.stringify(item[0]) === JSON.stringify(sendToChart[0]) && item[1] === sendToChart[1]
    );
    setShowChart(isInChart);
  }, [chartDataList, reference])
    useEffect(() => {
      let visitorAverage, homeAverage;
      let visitorNormed, homeNormed;
      let visitorZ, homeZ;
      view = parseInt(view)
      if (view === 0) {
        if (dictionaryReference.Views.Total) {
        setVisVal(visitorValue);
        setHomeVal(homeValue);
        }
        else {
          visitorAverage = visitorValue / reference.VisitorGames;
          setVisVal(visitorAverage.toFixed(1));
          homeAverage = homeValue / reference.HomeGames;
          setHomeVal(homeAverage.toFixed(1));
        }
      } else if (view === 1) {
        if (dictionaryReference.Views.Average) {
          visitorAverage = visitorValue / reference.VisitorGames;
          setVisVal(visitorAverage.toFixed(1));
          homeAverage = homeValue / reference.HomeGames;
          setHomeVal(homeAverage.toFixed(1))
        } else {
          setVisVal(visitorValue)
          setHomeVal(homeValue)
        }
      } else if (view === 2) {
        if (dictionaryReference.Views.Normed) {
          if (!dictionaryReference.Views.Total || (
            dictionaryReference.Views.Total && dictionaryReference.Views.Average
          )) {
            visitorValue = visitorValue / reference.VisitorGames
            homeValue = homeValue / reference.HomeGames
            visitorNormed = (visitorValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
            homeNormed = (homeValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
            setVisVal(visitorNormed.toFixed(1));
            setHomeVal(homeNormed.toFixed(1));
          } else {
            visitorNormed = (visitorValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
            homeNormed = (homeValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
            setVisVal(visitorNormed.toFixed(1));
            setHomeVal(homeNormed.toFixed(1));
          }

        } else {
          if (!dictionaryReference.Views.Total || (
            dictionaryReference.Views.Total && dictionaryReference.Views.Average
          )) {
            visitorAverage = visitorValue / reference.VisitorGames;
            homeAverage = homeValue / reference.HomeGames;
            setVisVal(visitorAverage.toFixed(1));
            setHomeVal(homeAverage.toFixed(1));
          } else {
            setVisVal(visitorValue)
            setHomeVal(homeValue)
          }
        }
      } else if (view === 3) {
        if (dictionaryReference.Views.Zeta) {
          if (!dictionaryReference.Views.Total || (
            dictionaryReference.Views.Total && dictionaryReference.Views.Average
          )) {
            visitorValue = visitorValue / reference.VisitorGames
            homeValue = homeValue / reference.HomeGames
            visitorZ = (visitorValue - reference.CurrentContext.Mean) / reference.CurrentContext.Standard_Deviation;
            homeZ = (homeValue - reference.CurrentContext.Mean) / reference.CurrentContext.Standard_Deviation;
            setVisVal(visitorZ.toFixed(1));
            setHomeVal(homeZ.toFixed(1));
          } else {
            visitorZ = (visitorValue - reference.CurrentContext.Mean) / reference.CurrentContext.Standard_Deviation;
            homeZ = (homeValue - reference.CurrentContext.Mean) / reference.CurrentContext.Standard_Deviation;
            setVisVal(visitorZ.toFixed(1));
            setHomeVal(homeZ.toFixed(1));
          }
        } else {
          if (!dictionaryReference.Views.Total || (
            dictionaryReference.Views.Total && dictionaryReference.Views.Average
          )) {
            visitorAverage = visitorValue / reference.VisitorGames;
            homeAverage = homeValue / reference.HomeGames;
            setVisVal(visitorAverage.toFixed(1));
            setHomeVal(homeAverage.toFixed(1));
          } else {
            setVisVal(visitorValue)
            setHomeVal(homeValue)
          }
        }
      }

  }, [view, visitorValue, homeValue, reference]);


  const toggleToChart = () => {
    const sendToChart = [reference.ref, reference.index];
    const isInChart = chartDataList.some(
      (item) => JSON.stringify(item[0]) === JSON.stringify(sendToChart[0]) && item[1] === sendToChart[1]
    );

    let updatedList;
    if (isInChart) {
      // If it is already in chartDataList, clear updatedList (make it empty)
      updatedList = [];
      setShowChart(false);
    } else {
      // Replace with only the new sendToChart item
      updatedList = [sendToChart];
      setShowChart(true);
    }
    
    setChartDataList(updatedList);
  };
  const getBackgroundColor = () => {
    return dictionaryReference.isDerogatory ? "rgba(255, 0, 0, 0.075)" : "rgba(0, 255, 0, 0.075)";
  }
    
  const breadCrumb = IndexReference(splitIndex)

  let category = reference.ref.slice(0, -1).join(' / ')

  return (
    <Box className={classes.container} style={{ backgroundColor: getBackgroundColor() }}>
      <Box className={classes.topPanel}>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="body2"
          >
            {category + ' / ' + breadCrumb.join(' / ')}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box className={classes.bottomPanel}>
        <Box className={classes.middlePanel}>
          <Tooltip 
            title={
              <React.Fragment>
                <Typography color="inherit">View in Chart</Typography>
              </React.Fragment>
            } 
            placement="top">
            <TimelineIcon
              alt="icon"
              onMouseDown={(e) => e.stopPropagation()}
              onClick={toggleToChart}
              className={`${classes.icon} ${showChart ? classes.icontoggled : ''}`}
            />
          </Tooltip>
          <Box className={classes.titlePane}>
            <Typography variant="subtitle1">{title}</Typography>
            {
              dictionaryReference.isDerogatory ?
              <Tooltip 
                title={
                  <React.Fragment>
                    <Typography color="inherit">This is a negative stat. Lower values are better than higher values</Typography>
                  </React.Fragment>
                } 
              placement="top">
             <ErrorTwoToneIcon 
                className={`${classes.derogIcon} ${view === 1 ? classes.selectedViews : ''}`}
                />
              </Tooltip> : null }

          </Box>
            <Box className={classes.iconContainer}>
                    {/* Total, Accumulated */}
                    <Tooltip  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        {
                          dictionaryReference.Views.Total ? 
                          <Typography color="inherit" variant='body2'>{`Cumulative Total is Available For This Stat`}</Typography>
                          : <Typography color="inherit" variant='body2'>{`Cumulative Total is Not Available For This Stat`}</Typography>
                        
                        }
  
                      </React.Fragment>
                        } placement="bottom">
                      <EqualizerIcon alt="icon" className={dictionaryReference.Views.Total ? classes.availableView : classes.unavailableView} id={0} />
                    </Tooltip>
                    {/* Average Per Game */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                      {
                          dictionaryReference.Views.Average ? 
                          <Typography color="inherit" variant='body2'>{`Average Per Game is Available For This Stat`}</Typography>
                          : <Typography color="inherit" variant='body2'>{`Average Per Game is Not Available For This Stat`}</Typography>
                        
                        }
                      </React.Fragment>
                      } placement="bottom">
                    <TrendingUpIcon alt="icon" className={dictionaryReference.Views.Average? classes.availableView : classes.unavailableView} id={1}/>
                    </Tooltip>
                    {/* League Normalized Values */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        {
                          dictionaryReference.Views.Normed ? 
                          <Typography color="inherit" variant='body2'>{`League Normalized is Available For This Stat`}</Typography>
                          : <Typography color="inherit" variant='body2'>{`League Normalized is Not Available For This Stat`}</Typography>
                        }
                      </React.Fragment>
                      } placement="top">
                    <MultilineChartIcon alt="icon" className={dictionaryReference.Views.Normed ? classes.availableView : classes.unavailableView} id={2}/>
                    </Tooltip>
                    {/* Z-Scores */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        {
                          dictionaryReference.Views.Zeta ? 
                          <Typography color="inherit" variant='body2'>{`Z-Scores are Available For This Stat`}</Typography>
                          : <Typography color="inherit" variant='body2'>{`Z-Scores are Not Available For This Stat`}</Typography>
                        }
                      </React.Fragment>
                      } placement="top">
                    <BarChartIcon alt="icon" className={dictionaryReference.Views.Zeta? classes.availableView : classes.unavailableView} id={3}/>
                    </Tooltip>
            </Box>
            <Box className={classes.visitorPane}>
              <Typography variant="subtitle1">
                {`${visVal}${dictionaryReference.format === 'percentage' && view === 0 ? ' %' : ''}`}
              </Typography>
            </Box>
            <Box className={classes.homePane}>
              <Typography variant="subtitle1">
                {`${homeVal}${dictionaryReference.format === 'percentage' && view === 0 ? ' %' : ''}`}
              </Typography>
            </Box>
        </Box>
      </Box>
    </Box>
  );
};


export default DataRow;
