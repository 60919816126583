import React, { useEffect, useState,useContext } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Typography, IconButton, List, ListItem, ListItemText, Box,
  Paper
} from '@material-ui/core';
import { Folder, InsertDriveFile, ArrowBack } from '@material-ui/icons';
import SelectorModule from './SelectorModule.jsx';
import CollectionManager from './CollectionManager.jsx';


const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    width: '90%',
    height: '90%',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  dataExplorerContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  
  fileTree: {
    width: '25%',
    margin: theme.spacing(0),
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '30%',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  fileTreeList: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '33vh', // Ensure height is set for responsiveness
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  collectionManager: {
    width: '30%',
    margin: theme.spacing(0),
    borderRight: `1px solid ${theme.palette.divider}`,
    // Remove overflow from the parent to prevent double scrollbars
    // overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50vh', // Ensure height is set for responsiveness
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  fileContent: {
    flex: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  breadcrumb: {
    margin: theme.spacing(0),
  },
  viewToggle: {
    marginLeft: 'auto',
  },
  gridView: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '100px',
      margin: theme.spacing(0),
    },
  },
  listView: {
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.type === 'dark' ? '#FFD580' : '#B8860B', // Manilla color for folders
  },
  fileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary, // Neutral color based on text
  },
  listItem: {
    justifyContent: 'flex-start',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
}));

const DataExplorer = () => {
  const { fetchFromAPI, dictionary, activeLeague } = useContext(DashboardContext);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [path, setPath] = useState([]);
  const [files, setFiles] = useState([])
 useEffect(() => {
    setFiles(dictionary)
 }, [dictionary])

  const handleItemClick = async (event, item) => {
    if (item.type === 'folder') {
      setPath([...path, item.name]);
      setFiles(item.contents);
    } else if (item.type === 'file') {
      let stat = await fetchFromAPI('v3/statDefinition', 'POST', { league: activeLeague, statName: item.statName, ref: item.ref })
      setSelectedFile(stat)
    }
    
  };
    return (
      <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h6">Stat Explorer</Typography>
          <div className={classes.dataExplorerContainer}>
            <div className={classes.fileTree}>
              <IconButton 
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => {
                    setFiles(dictionary)
                    setSelectedFile(null)
                  }}> 
                  <ArrowBack />
                  <Typography variant="body1">Back</Typography>
                </IconButton>
              <List className={classes.fileTreeList}>
                {files.map((file, index) => (
                  <ListItem button 
                    key={index}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                        handleItemClick(e, file)}
                      }
                    className={classes.listItem}>
                    {file.type === 'folder' ? <Folder className={classes.icon} /> : <InsertDriveFile className={classes.fileIcon} />}
                    <ListItemText primary={file.type === 'folder' ? file.name : file.statName} />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className={classes.fileContent}>
            
            { selectedFile ?
             <Box pl={2} pt={0} pb={0} pr={0}>
              <Box position="sticky"
                top={0}
                zIndex={1000}
                borderRadius={4}
                bgcolor="background.paper"
                p={3}
                display="flex"              // Add this line
                flexDirection="row"         // Add this line
                alignItems="center"         // Optionally, center-align items vertically
                > 
              </Box>
              <SelectorModule file={selectedFile} filters={selectedFile.Filters}/>
              <Box pl={2} pt={2} pb={2} pr={0}>
                <Typography variant="h6">Explanation</Typography>
                <Typography variant="body1">{selectedFile['NoviceExplanation']}</Typography>
                <Typography variant="h6">Sports Bettor's Insight</Typography>
                <Typography variant="body1">{selectedFile[`BettorInsight`]}</Typography>
              </Box>
            </Box> : <Box mt={2}>
                <Typography variant="h6" align="center">
                  Select A Stat For More Selection Options
                </Typography>
            </Box>
            }
      </div>
      <div className={classes.collectionManager} >
        <CollectionManager />
      </div>
    </div>
    </Paper>
  );
};

export default DataExplorer;
