import React, { useContext, useState } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from './CustomAlert.jsx';
import {
  Typography,
  Box,
  Paper,
  Button,
  Grid,
  TextField,
  Card,
  CardMedia,
  Tooltip,
  Alert,
} from '@material-ui/core';
import aiImage from '../v4/chloeBooth.png';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflow: 'auto',
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.up('xl')]: {
      width: '35%',
      marginLeft: theme.spacing(1),
      // maxHeight: 700,
    },
    [theme.breakpoints.down('lg')]: {
      width: '48%',
      marginLeft: theme.spacing(1),
      // maxHeight: 700,
    },
    [theme.breakpoints.down('md')]: {
      // height: '100%',
      maxHeight: 550,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  imageCard: {
    maxWidth: 345,
    margin: 'auto',
  },
  media: {
    height: 140,
  },
  responseTextArea: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  banner: {
    marginBottom: theme.spacing(2),
  },
}));

const AIGuidance = () => {
  const { game } = useContext(DashboardContext);
  const classes = useStyles();

  const [response] = useState('');

  if (!game) {
    return null; // Render nothing if `game` is null or undefined
  }

  return (
    <Box component={Paper} className={classes.componentContainer}>
      <CustomAlert severity="info">
        This feature is coming soon! Stay tuned for expert AI analysis.
      </CustomAlert>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
        AI Expert Analysis
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {['Analysis', 'Strategy', 'Advice'].map((label) => (
            <Tooltip
              title="This feature is coming soon!"
              placement="top"
              key={label}
            >
              <span> {/* Wrapper to allow tooltip for disabled buttons */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled
                >
                  {label}
                </Button>
              </span>
            </Tooltip>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.imageCard}>
            <CardMedia
              className={classes.media}
              image={aiImage}
              title="AI Image"
            />
          </Card>
        </Grid>
      </Grid>
      <TextField
        className={classes.responseTextArea}
        label="AI Response"
        multiline
        rows={6}
        variant="outlined"
        value={response}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        placeholder="Response will appear here when the feature is live."
      />
    </Box>
  );
};

export default React.memo(AIGuidance);
