import React, { useContext, useEffect, useState } from 'react';
import {
  Table, TableBody, TableRow, TableCell, Typography, Box, Paper,
  RadioGroup, Radio, FormControl, FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v4Context.js';
import BookmakerSelect from './BookmakerSelect.jsx';
import OddsChart from "./OddsChart.jsx";

// Color gradient functions remain the same
const getGradientColor = (value, min, max) => {
  value = parseFloat(value);
  min = parseFloat(min);
  max = parseFloat(max);

  if (isNaN(value) || isNaN(min) || isNaN(max)) {
    return 'rgba(255, 255, 255, 0)';
  }

  if (min === max) {
    return 'rgba(128, 128, 128, 0.5)';
  }

  const percent = (value - min) / (max - min);
  const red = Math.round(255 * (1 - percent));
  const green = Math.round(255 * percent);

  return `rgba(${red}, ${green}, 0, 0.5)`;
};



const getRevGradientColor = (value, min, max) => {
  value = parseFloat(value);
  min = parseFloat(min);
  max = parseFloat(max);

  if (isNaN(value) || isNaN(min) || isNaN(max)) {
    return 'rgba(255, 255, 255, 0)';
  }

  if (min === max) {
    return 'rgba(128, 128, 128, 0.5)';
  }

  const percent = (max - value) / (max - min);
  const red = Math.round(255 * (1 - percent));
  const green = Math.round(255 * percent);

  return `rgba(${red}, ${green}, 0, 0.5)`;
};

const useStyles = makeStyles((theme) => ({
    componentContainer: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      flexDirection: 'column',
      display: 'flex',
      height: 'auto',
      overflow: 'auto',
      // minWidth: 300,
    [theme.breakpoints.up('xl')]: {
    //   width: '98%',
    //   maxWidth: 800
    },
    [theme.breakpoints.down('lg')]: {
        // width: '98%', // 40% width for large screens
        // maxWidth: 800,
      // marginLeft: theme.spacing(0),
      // marginRight: theme.spacing(2),
      // height: '100%',
      // maxHeight: 500,
      // minWidth: 250,
    },
    [theme.breakpoints.down('md')]: {
      // minWidth: 500,   // Example min width for extra-large screens
      // width: '100%', // 40% width for large screens
      // // maxHeight: 500,
      // height: 500,
      // width: '30%', // 60% width for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      margin: theme.spacing(1),
      width: '98%', // 100% width for small screens like tablets and phones
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // height: '70vh',
    },
    },
    columnsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    column: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(.25),
    },
    bookmakerCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(1),
      margin: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0), // Added padding for better spacing
      paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    bookmakerInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center', // Vertically centers items
      justifyContent: 'flex-end', // Evenly spaces items horizontally
      width: '100%', // Ensures the container spans full width
      gap: theme.spacing(4), // Optional: Adds space between the items
    },
    bookmakerInfoRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Vertically centers items
        justifyContent: 'flex-start', // Evenly spaces items horizontally
        width: '100%', // Ensures the container spans full width
        gap: theme.spacing(4), // Optional: Adds space between the items
    //   gap: theme.spacing(2), // Optional: Adds space between the items
    },
    selectContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    sortContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    highlightedBorder: {
      border: `4px solid ${theme.palette.primary.main}`,
    },
  }));

const BookmakerCard = ({ bookmaker, isVisitor, gradientProps, isSelected, classes }) => {
  const { backgroundColor } = gradientProps;
  const { getBookmakerLink } = useContext(DashboardContext);
  if (isVisitor) {
  return (
    <Box 
      className={`${classes.bookmakerCard} ${isSelected ? classes.highlightedBorder : ''}`}
      style={{ backgroundColor }}
    >
     
      <Box className={classes.bookmakerInfo}>
        {/* The Logo */}
        {/* <img src={`https://${getBookmakerLink(bookmaker.bookmaker)[2]}`} alt="" width="70" height="70" /> */}
        {/* 1. Name */}
        <Typography variant="body1">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
        {/* 2. True Implied Probability */}
        <Typography variant="body1">{parseFloat(((bookmaker.closeImpProbability*100)- bookmaker.closeOverRound)).toFixed(1)}%</Typography>
        <Typography variant="body1">% {parseFloat(bookmaker.closeOverRound).toFixed(1)}</Typography>
        {!bookmaker.closePoint && (
          <Typography variant="h6">
            {bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}
          </Typography>
        )}
        {bookmaker.closePoint && (
          <>
            <Typography variant="body1">
              {bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}
            </Typography>
            <Typography variant="h6">{bookmaker.closePoint}</Typography>
          </>
        )}
      </Box>
    </Box>
  )
} else {
    return (
        <Box 
          className={`${classes.bookmakerCard} ${isSelected ? classes.highlightedBorder : ''}`}
          style={{ backgroundColor }}
        >
         
          <Box className={classes.bookmakerInfoRight}>
            {bookmaker.closePoint && (
              <>
                <Typography variant="h6">{bookmaker.closePoint}</Typography>
                <Typography variant="body1">
                  {bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}
                </Typography>
              </>
            )}
            {!bookmaker.closePoint && (
              <Typography variant="h6">
                {bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}
              </Typography>
            )}
            <Typography variant="body1">{parseFloat(bookmaker.closeOverRound).toFixed(1)} %</Typography>
            {/* 2. True Implied Probability */}
            <Typography variant="body1">{parseFloat(((bookmaker.closeImpProbability*100)- bookmaker.closeOverRound)).toFixed(1)} % </Typography>
            {/* 1. Name */}
            <Typography variant="body1">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
          </Box>
        </Box>
      )
}
};

const HeadingCard = ({ isVisitor, market }) => {
    const classes = useStyles();
    if (isVisitor) {
        return (
          <Box 
            className={`${classes.bookmakerCard} : ''}`}
            // style={{ backgroundColor }}
          >
           
            <Box className={classes.bookmakerInfo}>
              {/* The Logo */}
              {/* <img src={`https://${getBookmakerLink(bookmaker.bookmaker)[2]}`} alt="" width="70" height="70" /> */}
              {/* 1. Name */}
              <Typography variant="body1">Bookmaker</Typography>
              {/* 2. True Implied Probability */}
              <Typography variant="body1">Win %</Typography>
              <Typography variant="body1">Vig</Typography>
              { market === 'Moneyline' && (
                <Typography variant="body1">
                  Odds
                </Typography>
              )}
              { market !== 'Moneyline' && (
                <>
                  <Typography variant="body1">
                    Odds
                  </Typography>
                  <Typography variant="h6">Points</Typography>
                </>
              )}
            </Box>
          </Box>
        )
      } 
      else {
        return (
            <Box 
              className={`${classes.bookmakerCard} : ''}`}
            >
             
              <Box className={classes.bookmakerInfoRight}>
                {/* The Logo */}
                {/* <img src={`https://${getBookmakerLink(bookmaker.bookmaker)[2]}`} alt="" width="70" height="70" /> */}
                {/* 1. Name */}
                {/* 2. True Implied Probability */}
                { market !== 'Moneyline' && (
                    <>
                    <Typography variant="h6">Points</Typography>
                    <Typography variant="body1">
                      Odds
                    </Typography>
                  </>
                )}
                { market === 'Moneyline' && (
                    <Typography variant="body1">
                    Odds
                  </Typography>
                )}
                    <Typography variant="body1">Vig</Typography>
                    <Typography variant="body1">Win %</Typography>
                    <Typography variant="body1">Bookmaker</Typography>
              </Box>
            </Box>
          )
      }
}

const VLineShop = () => {
  const classes = useStyles();
  const { selectedBookmakers, game, fetchFromAPI, betType, getBookmakerLink } = useContext(DashboardContext);
  const [visitorArray, setVisitorArray] = useState([]);
  const [homeArray, setHomeArray] = useState([]);
  const [visitorTeamName, setVisitorTeamName] = useState('');
  const [homeTeamName, setHomeTeamName] = useState('');
  const [visitorIcon, setVisitorIcon] = useState('');
  const [homeIcon, setHomeIcon] = useState('');
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('price');

  useEffect(() => {
    setVisitorArray([]);
    setHomeArray([]);
    setVisitorTeamName('');
    setHomeTeamName('');
    setVisitorIcon('');
    setHomeIcon('');
    const fetchData = async () => {
      try {
        if (game) {
          let lineShopData = await fetchFromAPI('v3/lineShop', 'POST', { game: game.oddsAPIId, betType });
          let shopKeys = Object.keys(lineShopData)

          let visKeys = game.visitor.alias
          visKeys.push(game.visitor.teamName)
          visKeys.push(game.visitor.natstatId)

          for (let i = 0; i < visKeys.length; i++) {
            if (shopKeys.includes(visKeys[i])) {
              lineShopData[game.visitor.teamName] = lineShopData[visKeys[i]]
              break
            }
          }

          let homeKeys = game.home.alias
          homeKeys.push(game.home.teamName)
          homeKeys.push(game.home.natstatId)

          for (let i = 0; i < homeKeys.length; i++) {
            if (shopKeys.includes(homeKeys[i])) {
              lineShopData[game.home.teamName] = lineShopData[homeKeys[i]]
              break
            }
          }

          if (lineShopData && (betType === 'Spreads' || betType === 'Moneyline')) {
            
            if (lineShopData[game.visitor.teamName] || lineShopData[game.visitor.natstatId] || game.visitor) {
              setVisitorArray(lineShopData[game.visitor.teamName] || lineShopData[game.visitor.natstatId]);
              setVisitorTeamName(game.visitor.teamName);
              setVisitorIcon(<img src={game.visitor.logoURL} alt="" width="70" height="70" onError={(e) => {
                e.target.style.display = 'none'; // Hide the image
              }}/>);
            }

            if (lineShopData[game.home.teamName] || lineShopData[game.home.natstatId]) {
              setHomeArray(lineShopData[game.home.teamName] || lineShopData[game.home.natstatId]);
              setHomeTeamName(game.home.teamName);
              setHomeIcon(<img src={game.home.logoURL} alt="" width="70" height="70" onError={(e) => {
                e.target.style.display = 'none'; // Hide the image
              }}/>);
            }
          } else if (lineShopData && betType === 'Totals') {
            setVisitorArray(lineShopData['Over'] || []);
            setVisitorTeamName('Over');
            setHomeArray(lineShopData['Under'] || []);
            setHomeTeamName('Under');
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load line shop data. Please try again later.');
      }
    };
    fetchData();
  }, [game, betType])

  const reorderBookmakers = (bookmakers, sort) => {
    // const normalizedSelectedBookmakers = selectedBookmakers.map(name => name.toLowerCase());
    const selected = [];
  
    bookmakers.forEach(bookmaker => {
      bookmaker.name = getBookmakerLink(bookmaker.bookmaker)[0]
      
      selected.push(bookmaker);
    });
    // console.log(selected[0])
    // Adjust sorting order based on `sort` parameter
    if (sort === 'price') {
      selected.sort((a, b) => b.closeOdds - a.closeOdds);
    } else if (sort === 'point') {
      selected.sort((a, b) => b.closePoint - a.closePoint);
    } else if (sort === 'overround') {
      selected.sort((a, b) => a.closeOverRound - b.closeOverRound);
    } else  if (sort === 'impprob') {
        selected.sort((a, b) => b.closeImpProbability - a.closeImpProbability);
    } else if (sort === 'alpha') {
        selected.sort((a, b) => b.name - a.name);
    } else {
        selected.sort((a, b) => b.closeOdds - a.closeOdds);
    }
  
    return selected;
};

  // Calculate the min and max odds to determine the gradient range
  const visitorMinOdds = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closeOdds))) : 0;
  const visitorMaxOdds = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closeOdds))) : 0;
  const visitorMaxPoint = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closePoint))) : 0;
  const visitorMinPoint = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closePoint))) : 0;
  const visitorMaxOverround = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closeOverRound))) : 0;
  const visitorMinOverround = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closeOverRound))) : 0;
  const visitorMaxImpP = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closeImpProbability))) : 0;
    const visitorMinImpP = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closeImpProbability))) : 0;

  const homeMinOdds = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closeOdds))) : 0;
  const homeMaxOdds = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closeOdds))) : 0;
  const homeMaxPoint = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closePoint))) : 0;
  const homeMinPoint = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closePoint))) : 0;
  const homeMaxOverround = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closeOverRound))) : 0;
  const homeMinOverround = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closeOverRound))) : 0;
    const homeMaxImpP = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closeImpProbability))) : 0;
    const homeMinImpP = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closeImpProbability))) : 0;

  function SortSelect() {
    return (
      <FormControl component="fieldset">
        <Box className={classes.sortContainer}>
          <Typography variant="subtitle1" style={{ marginRight: '2px' }}>
            Sort By:
          </Typography>
          <RadioGroup
            row
            aria-label="sortOrder"
            name="sortOrder"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <FormControlLabel value="alpha" control={<Radio color="primary" />} label="A-Z" />
            <FormControlLabel value="price" control={<Radio color="primary" />} label="Odds" />
            {betType !== 'Moneyline' && (
              <FormControlLabel value="point" control={<Radio color="primary" />} label="Point" />
            )}
            <FormControlLabel value="overround" control={<Radio color="primary" />} label="Vig" />
            <FormControlLabel value="impprob" control={<Radio color="primary" />} label="Win %" />
          </RadioGroup>
        </Box>
      </FormControl>
    );
  }

  if (!game) return <></>;
  if (error) return <Typography variant="body1" color="error">{error}</Typography>;

  // Calculate gradients and reorder bookmakers...
  const reorderedVisitorArray = reorderBookmakers(visitorArray, orderBy);
  const reorderedHomeArray = reorderBookmakers(homeArray, orderBy);
  
  return (
    <Paper className={classes.componentContainer}>
        <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Bookmakers Line Shop
        </Typography>
      <Box className={classes.selectContainer}>
        <BookmakerSelect />
        <SortSelect />
      </Box>

      <Box className={classes.columnsContainer}>
        {/* Visitor/Over Column */}
        <Box className={classes.column}>
          <Typography variant="h6" align="center">
            {betType === 'Totals' ? 'Over' : visitorTeamName}
          </Typography>
          <HeadingCard isVisitor={true} market={betType}/>
          {reorderedVisitorArray.map((bookmaker, index) => {
            let backgroundColor
            switch (orderBy) {
                case 'price':
                    backgroundColor = getGradientColor(bookmaker.closeOdds, visitorMinOdds, visitorMaxOdds);
                    break;
                case 'point':
                    backgroundColor = getGradientColor(bookmaker.closePoint, visitorMinPoint, visitorMaxPoint);
                    break;
                case 'overround':
                    backgroundColor = getRevGradientColor(bookmaker.closeOverRound, visitorMinOverround, visitorMaxOverround);
                    break;
                case 'impprob':
                    backgroundColor = getGradientColor(bookmaker.closeImpProbability, visitorMinImpP, visitorMaxImpP);
                    break;
                default:
                    backgroundColor = getGradientColor(bookmaker.closeOdds, visitorMinOdds, visitorMaxOdds);
            }
            const gradientProps = {
              backgroundColor: backgroundColor
            };

            return (
              <BookmakerCard
                key={index}
                bookmaker={bookmaker}
                isVisitor={true}
                gradientProps={gradientProps}
                isSelected={selectedBookmakers.includes(bookmaker.bookmaker)}
                classes={classes}
              />
            );
          })}
        </Box>

        {/* Home/Under Column */}
        <Box className={classes.column}>
          <Typography variant="h6" align="center">
            {betType === 'Totals' ? 'Under' : homeTeamName}
          </Typography>
          <HeadingCard isVisitor={false} market={betType}/>
          {reorderedHomeArray.map((bookmaker, index) => {
            let backgroundColor
            switch (orderBy) {
                case 'price':
                  backgroundColor = getGradientColor(bookmaker.closeOdds, homeMinOdds, homeMaxOdds);
                  break;
                case 'point':
                  backgroundColor = getGradientColor(bookmaker.closePoint, homeMinPoint, homeMaxPoint);
                  break;
                case 'overround':
                  backgroundColor = getRevGradientColor(bookmaker.closeOverRound, homeMinOverround, homeMaxOverround);
                  break;
                case 'impprob':
                    backgroundColor = getGradientColor(bookmaker.closeImpProbability, homeMinImpP, homeMaxImpP);
                    break;
                default:
                  backgroundColor = getGradientColor(bookmaker.closeOdds, homeMinOdds, homeMaxOdds);
              }
            const gradientProps = {
              backgroundColor: backgroundColor
            };

            return (
              <BookmakerCard
                key={index}
                bookmaker={bookmaker}
                teamIcon={betType === 'Totals' ? 
                  <Typography variant="h6">UNDER</Typography> : 
                  homeIcon}
                isVisitor={false}
                gradientProps={gradientProps}
                isSelected={selectedBookmakers.includes(bookmaker.bookmaker)}
                classes={classes}
              />
            );
          })}
        </Box>
      </Box>
      <OddsChart />
    </Paper>
  );
};

export default React.memo(VLineShop);