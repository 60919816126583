import React, { useContext } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Modal, Box, useMediaQuery } from '@material-ui/core/';
import { DashboardContext } from "./v4Context.js";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DataExplorer from "./DataExplorer.jsx";
import GameSummary from "./GameSummary.jsx";
import Transactions from "./Transactions.jsx";
import ComparisonTable from "./ComparisonTable.jsx";
import OddsChart from "./OddsChart.jsx";
import FirstTimeLeagueSelect from "./FirstTimeSelectLeague.jsx";
import HorizGameSummary from "./HorizGameSummary.jsx";
import CurrentBreakpoint from "../util/CurrentBreakPoint.jsx";
import LineShop from "./LineShop.jsx";
import AIGuidance from "./AIGuidance.jsx";
import FirstTimeGameSelect from "./FirstTimeGameSelect.jsx";
import HelpCrunchGoogleTag from "../components/HelpCrunchGoogleTag.jsx";
import { Redirect } from "react-router-dom";
import { useAuth } from "../util/auth.js";
import VLineShop from "./VLineShop.jsx";


const useStyles = makeStyles((theme) => ({
  componentsContainer: {
    // padding: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: '100%',
  },
  centerTopBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column', // Stack items vertically at 'md' breakpoint
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      flexDirection: 'column',
    },
  
  },
  leftBlock: {
    display: 'flex',
    // padding: theme.spacing(2),
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '35%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column', // Stack items vertically at 'md' breakpoint
      padding: theme.spacing(0),
      width: '38%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  
  rightBlock: {
    display: 'flex',
    flexDirection: 'row',
    // padding: theme.spacing(2),
    justifyContent: 'left',
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
    elevation: 0,
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
      width: '65%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '65%',
    },
    [theme.breakpoints.down('md')]: {
      width: '60%', // Adjusted to full width on smaller screens
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(2),
    //background color red
    backgroundColor: theme.palette.error.main,
  },
}));


const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();

  
  const { isDictionaryOpen, setDictionaryOpen, activeLeague, game } = useContext(DashboardContext);



  const handleClose = () => {
    setDictionaryOpen(false);  // Close the modal when clicking outside or pressing the close button
  };
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const accessDashboard = auth.user.planIsActive || 
      ( 
        auth.user.stripeSubscriptionStatus === 'active' ||
        auth.user.stripeSubscriptionStatus === 'trialing'
      )

      if (!accessDashboard) {
        return <Redirect to="/pricing" />;
      }


  return (
    <div className={classes.componentsContainer}>
      <CurrentBreakpoint />
      {/* <HelpCrunchGoogleTag /> */}
      {/* Modal for DataExplorer */}
      <Modal
        open={isDictionaryOpen}
        onClose={handleClose}  // Close modal on outside click
        className={classes.backdrop}
      >
        <div className={classes.modalContainer}>
          {/* Close Button (X) */}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content: DataExplorer */}
          <DataExplorer />
        </div>
      </Modal>

      {!activeLeague ? (
        <FirstTimeLeagueSelect />
      ) : !game ? ( <FirstTimeGameSelect /> ) : (
        <>
        <CurrentBreakpoint />
        <Box className={classes.centerTopBlock}>
        {isSmall && <GameSummary />}
        {!isSmall && 
                  <HorizGameSummary />
        }

        </Box>
        <Box className={classes.leftBlock}>
          <ComparisonTable />

        </Box>
        <Box className={classes.rightBlock}>
          {/* {isLg && <LineShop />} */}
          <VLineShop />
          <AIGuidance />
          <Transactions />
          {/* <OddsChart /> */}
        </Box>
        {/* <Box className={classes.rightBlock}> */}
          {/* {!isLg && <LineShop />} */}
          {/* {!isLg && <VLineShop />} */}
          {/* {!isLg && <OddsChart />}  */}
        {/* </Box> */}
          </>
      )
      
      }
      </div>
  );
};

export default Dashboard;
