import React from "react";
import { Button, Box } from "@material-ui/core";
import { useAuth } from "../util/auth.js";
import { Link } from "../util/router";

const ReturningCustomer = () => {
 const auth = useAuth();

 if (!auth.user) return null;

 return (
   <Box
     sx={{
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
     }}
   >
     <Button
       component={Link}
       to="/dashboard"
       variant="contained"
       size="large"
       sx={{
         textTransform: "none",
         bgcolor: "#3f51b5",
         color: "#fff",
         "&:hover": {
           bgcolor: "#2c387e"
         }
       }}
     >
       Go To My Dashboard
     </Button>
   </Box>
 );
};

export default ReturningCustomer;