import React, { useState, useContext } from 'react';
import {
  Select,
  MenuItem,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { DashboardContext } from './v4Context.js';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    alignContent: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    overflow: 'auto',
    width: 'auto',
  },
  select: {
    padding: theme.spacing(1),
    height: '45px',
    width: 'auto',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  leagueName: {
    fontWeight: 500,
  },
  iconButton: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const LeagueSelect = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  // Adjust breakpoint as needed (e.g. "down('xs')" if you want to show icon only for extra small screens)

  const { leagues, activeLeague, setActiveLeague } = useContext(DashboardContext);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setActiveLeague(event.target.value);
    setOpen(false);
  };

  if (!leagues || leagues.length === 0) {
    return null; // No leagues to display
  }

  /**
   * Mobile Layout
   * Shows only an IconButton which opens the select dialog when clicked
   */
  // if (isMobile) {
  //   return (
  //     <Box className={classes.componentContainer}>
  //       <IconButton
  //         onClick={handleOpen}
  //         className={classes.iconButton}
  //         aria-label="Select a League"
  //       >
  //         <ArrowDropDown />
  //       </IconButton>
  //       {/* We still render the Select, but keep it hidden until open is true */}
  //       <Select
  //         value={activeLeague || ''}
  //         onChange={handleChange}
  //         open={open}
  //         onClose={handleClose}
  //         className={classes.select}
  //         displayEmpty
  //         style={{ display: 'none' }} // Hide the select box visually, only used by the MUI modal
  //         renderValue={(selected) => (
  //           <Typography variant="body1" className={classes.leagueName}>
  //             {selected || 'Select a League'}
  //           </Typography>
  //         )}
  //       >
  //         <MenuItem value="" disabled>
  //           Select a league
  //         </MenuItem>
  //         {leagues.map((league, index) => (
  //           <MenuItem key={index} value={league} className={classes.menuItem}>
  //             <Typography variant="body1" className={classes.leagueName}>
  //               {league}
  //             </Typography>
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </Box>
  //   );
  // }

  /**
   * Larger Screens Layout
   * Displays the standard Select component
   */
  return (
    <Box className={classes.componentContainer}>
      <Select
        className={classes.select}
        value={activeLeague || ''}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => (
          <Typography variant="body1" className={classes.leagueName}>
            {selected || 'Select a League'}
          </Typography>
        )}
      >
        <MenuItem value="" disabled>
          Select a league
        </MenuItem>
        {leagues.map((league, index) => (
          <MenuItem key={index} value={league} className={classes.menuItem}>
            <Typography variant="body1" className={classes.leagueName}>
              {league}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default React.memo(LeagueSelect);
