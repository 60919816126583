import React, { useContext, useEffect, useState } from 'react';
import { DashboardContext } from './v4Context.js';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import { format, isToday, addDays, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    alignContent: 'center',
    height: 'auto',
    // If space is tight, consider forcing a smaller footprint on mobile
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  },
  select: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      margin: theme.spacing(0.5),
      fontSize: '0.8rem',
      padding: theme.spacing(0.5),
      minWidth: 120,
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid black',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  teamName: {
    marginLeft: theme.spacing(1),
    fontWeight: 500,
  },
  time: {
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
}));

const GameDropdown = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { fetchFromAPI, activeLeague, game, setGame, setGameData } = useContext(DashboardContext);

  const [gameList, setGameList] = useState(null);

  useEffect(() => {
    if (activeLeague) {
      setGame(null); // Clear selection when league changes
      setGameData(null);
      setGameList(null);

      fetchFromAPI('v3/games', 'POST', { league: activeLeague }).then((response) => {
        // Sort games by date in ascending order
        const sortedGames = response.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));
        setGameList(sortedGames);
      });
    }
  }, [activeLeague]);

  const formatEventTime = (eventTime) => {
    const eventDate = new Date(eventTime);
    const timeZone = 'America/New_York';
    const zonedDate = toZonedTime(eventDate, timeZone);
    const today = new Date();
    const tomorrow = addDays(today, 1);

    if (isToday(zonedDate)) {
      return `Today, ${format(zonedDate, 'hh:mm a')} EST`;
    } else if (isSameDay(zonedDate, tomorrow)) {
      return `Tomorrow, ${format(zonedDate, 'hh:mm a')} EST`;
    } else {
      return `${format(zonedDate, 'MMMM d, h:mm')} pm`;
    }
  };

  const handleGameChange = (event) => {
    const selectedGame = gameList.find((g) => g.oddsAPIId === event.target.value);
    if (selectedGame) {
      setGame(selectedGame);
    }
  };

  // If no games are available or still loading, return null
  if (!gameList) {
    return null;
  }

  return (
    <Box className={classes.componentContainer}>
      <Select
        className={classes.select}
        value={game?.oddsAPIId || ''}
        onChange={handleGameChange}
        displayEmpty
        renderValue={(selectedId) => {
          const selectedGame = gameList.find((g) => g.oddsAPIId === selectedId);
          return selectedGame ? (
            <div>
              <Typography variant="body1" className={classes.teamName}>
                {selectedGame.visitor.teamName} at {selectedGame.home.teamName}
              </Typography>
              <Typography variant="body2" className={classes.time}>
                {formatEventTime(selectedGame.eventTime)}
              </Typography>
            </div>
          ) : (
            'Select a Game'
          );
        }}
      >
        <MenuItem value="" disabled>
          Select a Game
        </MenuItem>
        {gameList.map((g) => {
          const homeIcon = g.home.logoURL;
          const visitorIcon = g.visitor.logoURL;
          return (
            <MenuItem key={g.oddsAPIId} value={g.oddsAPIId} className={classes.menuItem}>
              {/* Container that changes layout based on breakpoint */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'left',
                  gap: '8px',
                }}
              >
                {/* Visitor Section */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={visitorIcon}
                    alt={`${g.visitor.teamName} logo`}
                    style={{ width: 32, height: 32, }}
                    onError={(e) => {
                      e.target.style.display = 'none'; // Hide the image
                    }}
                  />
                  <Typography variant="body1" className={classes.teamName}>
                    {g.visitor.teamName}
                  </Typography>
                  <Typography variant="body2" style={{ margin: '0 8px' }}>
                    at
                  </Typography>
                </div>

                {/* Home Section */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={homeIcon}
                    alt={`${g.home.teamName} logo`}
                    style={{ width: 32, height: 32, }}
                    onError={(e) => {
                      e.target.style.display = 'none'; // Hide the image
                    }}
                  />
                  <Typography variant="body1" className={classes.teamName}>
                    {g.home.teamName}
                  </Typography>
                </div>
              </div>
              {/* Event time */}
              <Typography
                variant="body2"
                className={classes.time}
                style={{ marginTop: 4 }}
              >
                {formatEventTime(g.eventTime)}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default React.memo(GameDropdown);
