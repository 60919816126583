import React from "react";
import { Grid, Typography, Button, Box } from '@material-ui/core';
import monitor from './Monitor.png';
import degenLogo from './degenLogo.jpg';
import ListenToDegenerates from "./ListenToDegenerates";
import { Link } from "../util/router";
import { useAuth } from "../util/auth.js";

const KeyFeaturesList = () => (
  <Box component="ul" sx={{ listStyle: "disc", pl: 5 }}>
    <li>Select a league</li>
    <li>Pick a game</li>
    <li>Choose stats to view</li>
    <li>Instant reports</li>
    <li>Season stats and trends
      <Box component="ul" sx={{ listStyle: "disc", pl: 5 }}>
        <li>Season Totals, Recent Games</li>
        <li>As Home/Away, Fave/Dog, Conference Splits</li>
        <li>Total, Average Per Game, Normed and Z-Score Views</li>
        <li>Odds History for US Bookmakers</li>
        <li>Charts, Injuries, Trades and News.</li>
      </Box>
    </li>
  </Box>
);

const VideoSection = () => (
  <Box
    sx={{
      position: "relative",
      paddingBottom: "56.3%",
      height: 0,
      "& iframe": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "0 3px 5px rgba(0,0,0,0.3)"
      }
    }}
  >
    <iframe
      src="https://www.youtube.com/embed/jrHmLQ3muzo?si=69TkRYm9yg59DW0S&mute=1"
      title="Sports Betting Dashboard Demo"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Box>
);

const LandingPanel1v4 = ({
  campaign,
}) => {
  const auth = useAuth();

  return (
    <Box
      sx={{
        p: 4,
        pt: 0,
        maxWidth: "1400px",
        margin: "0 auto"
      }}
    >
      <Grid container spacing={4}>
        <Grid
          container
          width={'100%'}
          item
          xs={12}
          spacing={4}
          alignItems="center"
          justifyContent="flex-start"
          direction="row"
          wrap="wrap"
        >
          {/* Left Column */}
          <Grid item xs={12} sm={5}>
            <Typography variant="h1" align="right">NBA</Typography>
            <Typography variant="h4" align="right">Stats</Typography>
            <Typography variant="h4" align="right">Sportsbooks</Typography>
            <Typography variant="h4" align="right">Odds Histories</Typography>
            <Typography variant="h4" align="right">Injuries & Transactions</Typography>
            {/* Red, Whit and blue bars from the top down, horizontally */}
        
            {/* <KeyFeaturesList /> */}
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={7}
          sx={{
            maxWidth: "600px", // Limit the width to 600px
            width: "100%", // Ensure it’s responsive
            margin: "0 auto", // Center the content horizontally
          }}
          >
            <VideoSection />
            <Box
              component="img"
              src={monitor}
              alt="Statfactory Dashboard"
              sx={{
                width: "100%",
                height: "auto",
                display: "block",
              }}
            />
          </Grid>
        </Grid>


        <Grid container sx={{ textAlign: "center", mt: 4 }}>
          <Grid item xs={12}>
          
          </Grid>
        </Grid>

        <Grid 
          container 
          sx={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            mt: 2
          }} 
          spacing={2} 
          alignItems="center" 
          justifyContent="center"
        >
            <Button 
              component={Link}
              to={auth.user ? `/purchase/$standard` : `/auth/signup?next=/purchase/standard`}
              size="large"
              variant="contained"
              sx={{
                bgcolor: "#f15a29",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#d44c20"
                }
              }}
            >
              Try StatFactory.io
            </Button>
          {/* <Grid item xs={12} sm={4}>
            <Box
              component="img"
              src={degenLogo}
              alt="Degenerates"
              sx={{
                width: "100%",
                maxWidth: "400px",
                margin: "0 auto",
                borderRadius: 1
              }}
            />
          </Grid> */}

          {/* <Grid item xs={12} sm={8}>
            <Typography variant="h4">
              Welcome <Box component="span" sx={{ color: "#FFA500" }}>DEGENERATES</Box>!
            </Typography>
            <Typography variant="h5">
              To claim the Kendall and Hammer StatFactory.io discount, use the code:{' '}
              <Box component="strong">
                <Box component="span" sx={{ color: "#FFA500" }}>DEGENERATES</Box>
              </Box>
            </Typography>
            <ListenToDegenerates /> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPanel1v4;