import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import phoneImage from "./Phone.png";
import transactionsImage from "./Transactions.png";
import lineShopImage from "./LineShop.png";
import oddsHistoryImage from "./Oddshistory.png";
import dashboardImage from "./Dashboard.png";
import FourViewsComponent from "./FourViewsComponent";
import { Link } from "../util/router";
import { useAuth } from "../util/auth.js";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  headerImage: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    display: "block",
  },
    header: {
    textAlign: "center",
    },
  headerButton: {
    display: "flex",
    justifyContent: "center",
    // marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  section: {
    // marginTop: theme.spacing(),
    textAlign: "center",
  },
  sectionImage: {
    width: "100%",
    maxWidth: "800px",
    margin: "0px auto",
    display: "block",
    borderRadius: "5px",
    boxShadow: theme.shadows[3],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  transactionImage: {
    width: "100%",
    maxWidth: "600px",
    margin: "0px auto",
    display: "block",
    borderRadius: "5px",
    boxShadow: theme.shadows[3],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // borderRadius: theme.shape.borderRadius,
  },
  button: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
  subheader: {
    marginBottom: theme.spacing(2),
  },
}));

const LandingPanel2v4 = () => {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* Header Section */}
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h4">
            Save Unlimited <span style={{ color: "#FFA500" }}>Stat Collections</span> For Different Looks
          </Typography>
          <img src={phoneImage} alt="Statfactory Phone Dashboard" className={classes.headerImage} />
        </Grid>

        {/* Different Views Section */}
        <Grid item xs={12} className={classes.section}>
          <FourViewsComponent />
        </Grid>

        {/* Up-to-the-minute Injuries Section */}
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h4" className={classes.subheader}>
            Injuries & Transaction Timeline
          </Typography>
          <img
            src={transactionsImage}
            alt="Injuries and Transactions"
            className={classes.transactionsImage}
          />
        </Grid>
             {/* CTA Section */}
         <Grid container className={classes.buttonWrapper}>
          <Grid item xs={12}>
          <Button 
            component={Link}
            to={
              auth.user
              ? `/purchase/$standard`
              : `/auth/signup?next=/purchase/standard`
            }
            size="large"
            fullWidth={false}
            variant="contained" className={classes.button}>
              Try StatFactory.io
            </Button>
          </Grid>
        </Grid>
        {/* Line Shop Section */}
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h4" className={classes.subheader}>
            Line Shop Every US Sportsbook
          </Typography>
          <Typography variant="body1">
            Sort by Odds, Points, or Vig. Color-coded for instant value assessment.
          </Typography>
          <img
            src={lineShopImage}
            alt="Line Shop"
            className={classes.sectionImage}
          />
        </Grid>

        {/* Odds History Section */}
        <Grid item xs={12} className={classes.section}>
          <Typography variant="h4">
            Odds History Charts <span style={{ color: "#FFA500" }}>for Every Sportsbook</span>
          </Typography>
          <img
            src={oddsHistoryImage} // Replace with your odds history section image path
            alt="Odds History Charts"
            className={classes.sectionImage}
          />
          <Typography variant="h4">
            All in one, custom dashboard <span style={{ color: "#FFA500" }}>Save Time, Anaylze Faster</span>
        </Typography>
        
          <img
            src={dashboardImage} // Replace with your odds history section image path
            alt="Odds History Charts"
            className={classes.sectionImage}
          />
          <Typography variant="h4">
            <span style={{ color: "#FFA500" }}>Profit ?</span> ...
        </Typography>
        <Button 
            component={Link}
            to={
              auth.user
              ? `/purchase/$standard`
              : `/auth/signup?next=/purchase/standard`
            }
            size="large"
            fullWidth={false}
            variant="contained" className={classes.button}>
              Start Building Your Dashboard
            </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPanel2v4;
