import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { DashboardContext } from './v4Context.js';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import { max } from 'date-fns';

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York',
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0),
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100vw',
  },
  table: {
      width: '100%',
    },

  tableContainer: {
      margin: theme.spacing(1),
      // padding: theme.spacing(2),
    width: '100%',
  },
  tableCell: {
    padding: theme.spacing(1),
    maxWidth: 200,
    wordWrap: 'wrap',
  },
  logo: {
    maxWidth: 50,
    maxHeight: 50,
  },
  weatherIcon: {
    maxWidth: 45,
    maxHeight: 45,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adds a subtle drop shadow
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.info.light, 
  },
  headerRow: {
    backgroundColor: theme.palette.primary.contrastText
  },
}));

const HorizGameSummary = () => {
  const classes = useStyles();
  const { game, activeLeague } = useContext(DashboardContext);

  let resultsYet = Array.isArray(game.results) && game.results.length === 2;
  
  const headers = [
    resultsYet ? `Total Score: ${parseInt(game.results[0].score) + parseInt(game.results[1].score)}` : ``,
    'Matchup',
    "",
    'Time & Place',
    'Host',
    'Division',
    'Status',
    'Season Games',
    'League Rank',
    'Division Games',
    'Game Day',
    'Weather Forecast'
  ];


  if (!game) return null
  console.log(activeLeague)
  const kelvinToFahrenheit = (kelvin) => {
    return parseFloat((((kelvin - 273.15) * 9/5) + 32).toFixed(1))
  };
  const capitalizeDescription = (description) => {
    return description
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  };
  const visitorData = [
    <Typography variant='h5'>
      {

        `${resultsYet && (
          game.visitor.alias.includes(game.results[0]?.name) || 
          game.visitor.teamName === game.results[0]?.name ||
          game.visitor.natstatId === game.results[0]?.name
        ) 
        ? game.results[0]?.score 
        : game.results[1]?.score || ""}`
      }
    </Typography>,
      <img
      src={game.visitor.logoURL}
      className={classes.logo}
      alt="Visitor Logo"
      onError={(e) => {
        e.target.style.display = 'none'; // Hide the image
      }}
    />,
    game.visitor.teamName,
    `${formatDate(game.eventTime)}`,
    'Visitor',
    `${game.visitor.conference} ${game.visitor.division}`,
    game.visitor.status,
    game.visitor.seasonRecord,
    game.visitor.leagueRank,
    game.visitor.divisionRecord,
    game.weather ? capitalizeDescription(game.weather.weather[0].description) : 'N/A',
    game.weather ? <img src={`https://openweathermap.org/img/wn/${game.weather.weather[0].icon}@2x.png`} className={classes.weatherIcon} alt="visitor logo" /> : <ErrorTwoToneIcon />,
    ]
    const homeData = [
      <Typography variant='h5'>
      {

 `${resultsYet && (
      game.visitor.alias.includes(game.results[0]?.name) || 
      game.visitor.teamName === game.results[0]?.name ||
      game.visitor.natstatId === game.results[0]?.name
    ) 
      ? game.results[1]?.score 
      : game.results[0]?.score || ""}`}
      </Typography>,
      <img
        src={game.home.logoURL}
        className={classes.logo}
        alt="Visitor Logo"
        onError={(e) => {
          e.target.style.display = 'none'; // Hide the image
        }}
      />,
    game.home.teamName, 
    <span>{game.venue?.name || 'N/A'}<br></br>{game.venue?.location || 'N/A'}</span>,
    'Home',
    `${game.home.conference} ${game.home.division}`,
    game.home.status,
    game.home.seasonRecord,
    game.home.leagueRank,
    game.home.divisionRecord,
    game.weather ? `${kelvinToFahrenheit(game.weather.temp)}° F` : 'N/A',
    game.weather ? `Wind: ${game.weather.wind_speed.toFixed(0)} mph from ${game.weather.wind_deg}°`: 'N/A',
  ];


  return (
    <Box elevation={10} className={classes.componentContainer}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="game summary table">
          <TableHead>
            <TableRow className={classes.headerRow}>
              {headers.map((header, index) => (
                <TableCell key={index} className={classes.tableCell} align="center">
                  <Typography variant="body1">{header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
           
            <TableRow>
              {visitorData.map((data, index) => (
                <TableCell key={index} className={classes.tableCell} align="center">
                  <Typography variant="body1">{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {homeData.map((data, index) => (
                  <TableCell key={index} className={classes.tableCell} align="center">
                  <Typography variant="body1">{data}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HorizGameSummary;
