import React from "react";
import { useParams } from "react-router-dom";
import Meta from "../components/Meta.js";
import PricingSection from "../components/PricingSection.js";
import NewsletterSection from "../components/NewsletterSection.js";
import analytics from "../util/analytics.js";
import GoogleTag from '../components/GoogleTag.jsx';
import LandingPanel1v4 from "../PromoComponents/LandingPanel1v4.jsx";
import LandingPanel2v4 from "../PromoComponents/LandingPanel2v4.jsx";
import StatsComponent from "../PromoComponents/StatsComponentVideo.jsx";
import ReturningCustomer from "../PromoComponents/ReturningCustomer.jsx";

function PromoPage(props) {
      React.useEffect(() => {
        analytics.page();
      }, []);

      //expecting a campaignId parameter in the URL
      const { campaignId } = useParams();
      const campaigns = {
        nba: {
          title: "NBA",
          video: "https://www.youtube.com/embed/HpQQQT9tuLA?si=PGJHDrB1k1XNCTBw&mute=1"
        },
        ncaab: {
          title: "NCAA Basketball",
          video: "https://www.youtube.com/embed/HpQQQT9tuLA?si=PGJHDrB1k1XNCTBw&mute=1"
        },
        nfl: {
          title: "NFL",
          video: "https://www.youtube.com/embed/dcRFPXj3rzw?si=uXJcvvBd5baospkk&mute=1",
        },
        //Additional new campaigns can be added as new KV pairs
      };
    
      // Default fallback content
      const defaultCampaign = {
        title: "Welcome!",
        description: "Default landing page content.",
        image: "/images/default.jpg",
      };
    
      // Fetch the current campaign's data
      const currentCampaign = campaigns[campaignId] || defaultCampaign;


  return (
    <>
      <GoogleTag />
      <Meta />
      <ReturningCustomer />
      <LandingPanel1v4 campaign={currentCampaign} />
      <StatsComponent />
    
      <LandingPanel2v4 />
    
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Standard Plan Pricing"
        subtitle="One Simple Plan, includes a 7-day free trial."
      />
      {/* <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="We're constantly adding new features, new stats, and new sports. Sign up to be the first to know!"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default PromoPage;
