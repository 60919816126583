import React, { useState, useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter, Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import LeagueSelect from "../v4/LeagueSelect";
import GameDropdown from "../v4/GameDropdown.jsx";
import HelpCrunchGoogleTag from "./HelpCrunchGoogleTag.jsx";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
    // If you'd like the logo visible on small screens, remove this or adjust
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  drawerList: {
    // width: 250,
  },
  appBar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    marginBottom: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  toolbar: {
    zIndex: theme.zIndex.drawer + 1, // Keep Toolbar on top of Drawer
    padding: theme.spacing(0, 2),
  },
  // A container for left items so they can easily be hidden on small screens
  leftItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
    whiteSpace: "nowrap",
    // On very narrow screens, you can let them wrap if needed:
    // [theme.breakpoints.down('xs')]: {
    //   flexWrap: 'wrap',
    // },
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function Navbar(props) {
  const router = useRouter();
  const currentLink = router.pathname;
  const classes = useStyles();
  const auth = useAuth();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <>
      {/*
        Wrapping the Toolbar in a Container (with maxWidth="xl" or "lg")
        can help ensure the nav doesn't overflow horizontally on small
        screens. You can tweak maxWidth to your preference.
      */}
      <AppBar position="sticky" className={classes.appBar} elevation={3}>
        <Container maxWidth="lg" disableGutters>
          <Toolbar className={classes.toolbar}>
            {/* Left Section: Logo */}
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>

            {/* Spacer pushes other items to the right */}
            <div className={classes.spacer} />

            {/* Example: if you only want to show these two selects on /dashboard,
                wrap them in a Hidden that displays them for mdUp only
                and handles the smaller breakpoints in the drawer. */}
            <Hidden smDown implementation="css">
              {currentLink === "/dashboard" && (
                <div className={classes.leftItems}>
                  <LeagueSelect />
                  <GameDropdown />
                </div>
              )}
            </Hidden>

            {/* Desktop Menu (hidden on small) */}
            <Hidden smDown implementation="css">
              {!auth.user && (
                <Button component={Link} to="/auth/signin" color="inherit">
                  Sign in
                </Button>
              )}

              {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    Account
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={menuState?.id === "account-menu"}
                    anchorEl={menuState?.anchor}
                    getContentAnchorEl={null}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem>
                    <MenuItem component={Link} to="/settings/general">
                      Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}

              <HelpCrunchGoogleTag />

              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value ? <NightsStayIcon /> : <WbSunnyIcon />}
              </IconButton>
            </Hidden>

            {/* Mobile Menu button (hidden on larger screens) */}
            <Hidden mdUp implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {/* Close the drawer whenever user clicks on an item */}
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {/* If on Dashboard route, show these items on small screens too */}
          {currentLink === "/dashboard" && (
            <>
              <ListItem>
                <LeagueSelect />
              </ListItem>
              <ListItem>
                <GameDropdown />
              </ListItem>
              <Divider />
            </>
          )}

          {!auth.user && (
            <ListItem component={Link} to="/auth/signin" button>
              <ListItemText>Sign in</ListItemText>
            </ListItem>
          )}

          {auth.user && (
            <>
              <ListItem component={Link} to="/dashboard" button>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
              <ListItem component={Link} to="/settings/general" button>
                <ListItemText>Settings</ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}

          <Divider />
          {/* Theme Toggle */}
          <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value ? <NightsStayIcon /> : <WbSunnyIcon />}
            </IconButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

export default Navbar;
