import React, { useContext, useEffect, useState } from 'react';
import {
  Table, TableBody, TableRow, TableCell, Typography, Box, Paper,
  RadioGroup, Radio, FormControl, FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v4Context.js';
import BookmakerSelect from './BookmakerSelect.jsx';

// Function to calculate gradient color with 50% opacity
const getGradientColor = (value, min, max) => {
  // Ensure values are numbers
  value = parseFloat(value);
  min = parseFloat(min);
  max = parseFloat(max);

  // Handle invalid numbers
  if (isNaN(value) || isNaN(min) || isNaN(max)) {
    return 'rgba(255, 255, 255, 0)'; // Return transparent color
  }

  // Avoid division by zero
  if (min === max) {
    return 'rgba(128, 128, 128, 0.5)'; // Neutral color for equal min and max
  }

  // Adjust the gradient calculation to reverse the coloring
  const percent = (value - min) / (max - min); // Calculate percentage directly

  const red = Math.round(255 * (1 - percent));
  const green = Math.round(255 * percent);

  return `rgba(${red}, ${green}, 0, 0.5)`; // 50% opacity
};
const getRevGradientColor = (value, min, max) => {
  // Ensure values are numbers
  value = parseFloat(value);
  min = parseFloat(min);
  max = parseFloat(max);

  // Handle invalid numbers
  if (isNaN(value) || isNaN(min) || isNaN(max)) {
    return 'rgba(255, 255, 255, 0)'; // Return transparent color
  }

  // Avoid division by zero
  if (min === max) {
    return 'rgba(128, 128, 128, 0.5)'; // Neutral color for equal min and max
  }

  // Reverse the gradient calculation
  const percent = (max - value) / (max - min); // Reverse the gradient

  const red = Math.round(255 * (1 - percent));
  const green = Math.round(255 * percent);

  return `rgba(${red}, ${green}, 0, 0.5)` // 50% opacity
};
// Function to reorder selected bookmakers to the front of the array
const reorderBookmakers = (bookmakers, selectedBookmakers, sort) => {
  const normalizedSelectedBookmakers = selectedBookmakers.map(name => name.toLowerCase());
  const selected = [];
  const unselected = [];

  bookmakers.forEach(bookmaker => {
    if (normalizedSelectedBookmakers.includes(bookmaker.bookmaker.toLowerCase())) {
      selected.push(bookmaker);
    } else {
      unselected.push(bookmaker);
    }
  });

  // Adjust sorting order based on `sort` parameter
  if (sort === 'price') {
    selected.sort((a, b) => b.closeOdds - a.closeOdds);
    unselected.sort((a, b) => b.closeOdds - a.closeOdds);
  } else if (sort === 'point') {
    selected.sort((a, b) => b.closePoint - a.closePoint);
    unselected.sort((a, b) => b.closePoint - a.closePoint);
  } else if (sort === 'overround') {
    selected.sort((a, b) => a.closeOverRound - b.closeOverRound);
    unselected.sort((a, b) => a.closeOverRound - b.closeOverRound);
  }

  return [...selected, ...unselected];
};

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    // margin: theme.spacing(1),
    flexDirection: 'column',
    display: 'flex',
    height: 'auto',
    overflow: 'auto',
    [theme.breakpoints.up('xl')]: {
      // width: '68%',
    },
    [theme.breakpoints.up('lg')]: {
      
      // width: '98%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the logo
    width: '100%',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
    borderSpacing: '10px', // Increased spacing between cells
    borderCollapse: 'separate',
  },
  tableRow: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  tableCell: {
    padding: theme.spacing(1),
    width: '90px', // Fixed width to match titleCell
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
    textAlign: 'left',
    // Added margin between cells
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5),
  },
  titleCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content horizontally
    justifyContent: 'center', // Center content vertically
    border: `0px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    width: '90px', // Same width as tableCell
  },
  highlightedBorder: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  bookmakerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align content to the left
    // Adjusted margin inside the container for better spacing
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    
  },
}));

const LineShop = () => {
  const classes = useStyles();
  const { selectedBookmakers, game, fetchFromAPI, betType, getBookmakerLink } = useContext(DashboardContext);
  const [visitorArray, setVisitorArray] = useState([]);
  const [homeArray, setHomeArray] = useState([]);
  const [visitorTeamName, setVisitorTeamName] = useState('');
  const [homeTeamName, setHomeTeamName] = useState('');
  const [visitorIcon, setVisitorLogo] = useState('');
  const [homeIcon, setHomeIcon] = useState('');
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('price');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (game) {
          let lineShopData = await fetchFromAPI('v3/lineShop', 'POST', { game: game.oddsAPIId, betType });
          console.log('LineShopData', lineShopData)
          if (lineShopData && (betType === 'Spreads' || betType === 'Moneyline')) {
            setVisitorArray(lineShopData[game.visitor.teamName] || []);
            setVisitorTeamName(game.visitor.teamName || 'Visitor');
            setHomeArray(lineShopData[game.home.teamName] || []);
            setHomeTeamName(game.home.teamName || 'Home');
            setVisitorLogo(<img src={game.visitor.logoURL} alt="" width="70" height="70" />);
            setHomeIcon(<img src={game.home.logoURL} alt="" width="70" height="70" />);
          } else if (lineShopData && betType === 'Totals') {
            setVisitorArray(lineShopData['Over'] || []);
            setVisitorTeamName('Over');
            setHomeArray(lineShopData['Under'] || []);
            setHomeTeamName('Under');
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load line shop data. Please try again later.');
      }
    };
    fetchData();
  }, [game, betType]);

  // Calculate the min and max odds to determine the gradient range
  const visitorMinOdds = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closeOdds))) : 0;
  const visitorMaxOdds = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closeOdds))) : 0;
  const visitorMaxPoint = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closePoint))) : 0;
  const visitorMinPoint = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closePoint))) : 0;
  const visitorMaxOverround = visitorArray.length ? Math.max(...visitorArray.map(b => parseFloat(b.closeOverRound))) : 0;
  const visitorMinOverround = visitorArray.length ? Math.min(...visitorArray.map(b => parseFloat(b.closeOverRound))) : 0;

  const homeMinOdds = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closeOdds))) : 0;
  const homeMaxOdds = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closeOdds))) : 0;
  const homeMaxPoint = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closePoint))) : 0;
  const homeMinPoint = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closePoint))) : 0;
  const homeMaxOverround = homeArray.length ? Math.max(...homeArray.map(b => parseFloat(b.closeOverRound))) : 0;
  const homeMinOverround = homeArray.length ? Math.min(...homeArray.map(b => parseFloat(b.closeOverRound))) : 0;

  // Reorder the arrays based on selectedBookmakers
  const reorderedVisitorArray = reorderBookmakers(visitorArray, selectedBookmakers, orderBy);
  const reorderedHomeArray = reorderBookmakers(homeArray, selectedBookmakers, orderBy);

  if (!game) {
    return <></>;
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  function SortSelect() {
    const classes = useStyles(); // Ensure styles are accessible in this function
  
    return (
      <FormControl component="fieldset">
        <Box className={classes.sortContainer}>
          <Typography variant="subtitle1" style={{ marginRight: '16px' }}>
            Sort By:
          </Typography>
          <RadioGroup
            row
            aria-label="sortOrder"
            name="sortOrder"
            value={orderBy} // Control RadioGroup with orderBy state
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <FormControlLabel value="price" control={<Radio color="primary" />} label="Odds" />
            {
              betType !== 'Moneyline' && ( 
                <FormControlLabel value="point" control={<Radio color="primary" />} label="Point" />
              )
            }
            <FormControlLabel value="overround" control={<Radio color="primary" />} label="Vig" />
          </RadioGroup>
        </Box>
      </FormControl>
    );
  }
console.log('visitorArray', visitorArray)
console.log('homeArray', homeArray)
  return (
    <Paper className={classes.componentContainer}>
      <Box className={classes.selectContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Bookmakers Line Shop
        </Typography>
        <BookmakerSelect />
        <SortSelect />
      </Box>
      <Table className={classes.table}>
        <TableBody>
          {visitorArray.length > 0 && (
            <TableRow className={classes.tableRow}>
              <TableCell align="center" className={classes.titleCell}>
                {betType === 'Spreads' || betType === 'Moneyline' ? (
                  <span className={classes.logo}>{visitorIcon}</span>
                ) : (
                  <Typography variant="subtitle1">Over</Typography>
                )}
              </TableCell>
              {reorderedVisitorArray.map((bookmaker, index) => {
                let backgroundColor
                switch (orderBy) {
                  case 'price':
                    backgroundColor = getGradientColor(bookmaker.closeOdds, visitorMinOdds, visitorMaxOdds);
                    break;
                  case 'point':
                    backgroundColor = getGradientColor(bookmaker.closePoint, visitorMinPoint, visitorMaxPoint);
                    break;
                  case 'overround':
                    backgroundColor = getRevGradientColor(bookmaker.closeOverRound, visitorMinOverround, visitorMaxOverround);
                    break;
                  default:
                    backgroundColor = getGradientColor(bookmaker.closeOdds, visitorMinOdds, visitorMaxOdds);
                }
                const isSelected = selectedBookmakers.includes(bookmaker.bookmaker);
                return (
                  <TableCell
                    key={index}
                    align="left" // Set alignment to left
                    className={`${classes.tableCell} ${isSelected ? classes.highlightedBorder : ''}`}
                    style={{ backgroundColor }}
                  >
                    <Box className={classes.bookmakerContainer}>
                      <Typography variant="caption">% {parseFloat(bookmaker.closeOverRound).toFixed(1)}</Typography>
                      <Typography variant="body1">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                      {!bookmaker.closePoint &&  <Typography variant="h6">{bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}</Typography>}
                      {bookmaker.closePoint &&  <Typography variant="body1">{bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}</Typography>  }
                      {bookmaker.closePoint &&  <Typography variant="h6">{bookmaker.closePoint}</Typography> }
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          )}

          {homeArray.length > 0 && (
            <TableRow className={classes.tableRow}>
              <TableCell align="center" className={classes.titleCell}>
                {betType === 'Spreads' || betType === 'Moneyline' ? (
                  <span className={classes.logo}>{homeIcon}</span>
                ) : (
                  <Typography variant="subtitle1">Under</Typography>
                )}
              </TableCell>
              {reorderedHomeArray.map((bookmaker, index) => {
                let backgroundColor
                switch (orderBy) {
                  case 'price':
                    backgroundColor = getGradientColor(bookmaker.closeOdds, homeMinOdds, homeMaxOdds);
                    break;
                  case 'point':
                    backgroundColor = getGradientColor(bookmaker.closePoint, homeMinPoint, homeMaxPoint);
                    break;
                  case 'overround':
                    backgroundColor = getGradientColor(bookmaker.closeOverRound, homeMinOverround, homeMaxOverround);
                    break;
                  default:
                    backgroundColor = getGradientColor(bookmaker.closeOdds, homeMinOdds, homeMaxOdds);
                }
                const isSelected = selectedBookmakers.includes(bookmaker.bookmaker);
                return (
                  <TableCell
                    key={index}
                    align="left"
                    className={`${classes.tableCell} ${isSelected ? classes.highlightedBorder : ''}`}
                    style={{ backgroundColor }}
                  >
                    <Box className={classes.bookmakerContainer}>
                      {bookmaker.closePoint &&  <Typography variant="h6">{bookmaker.closePoint}</Typography> }
                      {bookmaker.closePoint &&  <Typography variant="body1">{bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}</Typography>}
                      {!bookmaker.closePoint &&  <Typography variant="h6">{bookmaker.closeOdds > 0 ? `+${bookmaker.closeOdds}` : bookmaker.closeOdds}</Typography>}
                      <Typography variant="body1">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                      <Typography variant="caption">% {parseFloat(bookmaker.closeOverRound).toFixed(1)}</Typography>
                    
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default React.memo(LineShop);
